import { template as template_e1645bc96e374b898850dbf925a4d84d } from "@ember/template-compiler";
const FKLabel = template_e1645bc96e374b898850dbf925a4d84d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_842cbb6ca82a40f39080134c0cfe5db5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_842cbb6ca82a40f39080134c0cfe5db5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

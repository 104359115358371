import { template as template_2579154ed5f8455a9e4c33eb8af1af1f } from "@ember/template-compiler";
const FKText = template_2579154ed5f8455a9e4c33eb8af1af1f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
